import { Tooltip } from "@canopyinc/aura";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

interface AboutButtonProps {
  tooltipContent?: string;
  className?: string;
}

export const AboutButton = ({ tooltipContent, className }: AboutButtonProps) => {
  const content = (
    <div className={cn("px-3 py-1 bg-primary-50 flex items-center rounded-xl border border-primary-100", className)}>
      <FontAwesomeIcon icon={faQuestionCircle} className="leading-none text-primary-600" />
      <span className="leading-none text-sm text-primary-800 ml-1.5">About</span>
    </div>
  );
  return tooltipContent ? (
    <Tooltip classNames={{ panel: "px-1 mt-2", wrapper: "z-10" }} content={tooltipContent} placement="bottom-end">
      {content}
    </Tooltip>
  ) : (
    content
  );
};
