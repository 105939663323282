import { useAPIReset } from "@/api/useApi";
import { useI18n } from "@/hooks/useI18n";
import { usePlugins } from "@/hooks/usePlugins";
import { Button, Dropdown, DropdownItem } from "@canopyinc/aura";
import { faChevronDown, faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";

export const AdminDropdown = ({ size }: { size?: "sm" | "md" | "lg" }) => {
  const plugins = usePlugins();
  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.admin_dropdown;

  const resetApiAndLogout = useAPIReset();
  const handleLogout = () => {
    resetApiAndLogout();
  };

  return (
    <div>
      <Dropdown
        direction="up"
        classNames={{ wrapper: "w-full" }}
        trigger={
          <Button
            size={size}
            variant="ghost"
            icon={<FontAwesomeIcon icon={faUserCircle} />}
            rightIcon={
              <div className="grow flex justify-end">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            }
            fullWidth
            classNames={{ button: "justify-start" }}
          >
            {dictionary?.title}
          </Button>
        }
      >
        <Link passHref href="https://docs.canopyservicing.com/docs" legacyBehavior>
          <a target="_blank" rel="noopener noreferrer">
            <DropdownItem>{dictionary?.fields?.docs}</DropdownItem>
          </a>
        </Link>

        {plugins?.team?.enabled ? (
          <Link passHref={true} href="/app/team">
            <DropdownItem>{dictionary?.fields?.team}</DropdownItem>
          </Link>
        ) : null}

        {plugins?.app_keys?.enabled ? (
          <Link passHref={true} href="/app/keys">
            <DropdownItem>{dictionary?.fields?.app_keys}</DropdownItem>
          </Link>
        ) : null}

        {plugins?.account_tag_options?.enabled ? (
          <Link passHref={true} href="/app/tag_options">
            <DropdownItem>{dictionary?.fields?.account_tag_options}</DropdownItem>
          </Link>
        ) : null}

        {plugins?.settings?.enabled ? (
          <Link passHref={true} href="/app/settings">
            <DropdownItem>{dictionary?.fields?.settings}</DropdownItem>
          </Link>
        ) : null}

        <div onClick={handleLogout}>
          <Link passHref={true} href="/signin">
            <DropdownItem>{dictionary?.fields?.logout}</DropdownItem>
          </Link>
        </div>
      </Dropdown>
    </div>
  );
};
